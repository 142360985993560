import Text from './utils/Text';

const Philosophy = () => {
  return (
    <Text>
      <p>
        Anfangs 2009 wagte ich mich an die erste Leinwand. Seither entstehen in meiner freien Zeit verschiedenste Leinwandproduktionen. Das Malen auf Holz oder Papier zählen zu den neuesten Versuchen.
      </p>
      <p>
        Nebst dem Gestalten auf Leinwänden, entwerfe ich gerne Karten, insbesondere Weihnachtskarten. Für jedes unserer Kinder schrieb ich in den Schwangerschaften eine Geschichte, die ich mit Aquarellfarben zu einem Bilderbuch illustriert habe. Dies war ein langgehegter Traum von mir.
      </p>
      <p>
        Inspiration für alle Kunstwerke bietet mir das tägliche Leben, Beobachtungen in der Natur und die Musik. Oftmals sind es Blitzideen, die dann während Tagen zu einem Bild im Kopf reifen und auf eine Umsetzung warten. 
      </p>
      <p>
        Malen zu können bedeutet für mich Freiheit, es ist achtsame ME-Time zum Auftanken und bringt nebenbei Ruhe für den Geist und Frieden für die Seele, ganz nach dem Motto:
      </p>
      <p style={{fontFamily: 'Fredericka the Great', fontSize: '22pt'}}>
        &laquo;Kunst wäscht den Staub des Alltags von der Seele&raquo;
      </p>
    </Text>
  );
}

export default Philosophy;
