import { FaWindowClose } from 'react-icons/fa';
import { useState, useContext } from 'react';
import AppContext from './appContext'

const styleModal = {
    position: 'fixed',
    zIndex: 10,
    backgroundColor: '#4c4848',
    left: 0,
    right: 0,
    bottom: 0,
    top: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

const styleModalMobile = {
    ...styleModal,
    top: 0
}

const styleClose = {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
    zIndex: 11
}

const Modal = (props) => {
    const [touchStartX, setTouchStartX] = useState(null);
    const appConsts = useContext(AppContext)
    const touchEnd = (e) => {
        
        if (touchStartX) {
            const touchEndX = e.changedTouches[0].pageX;
            if (touchStartX < touchEndX) {
                props.moveNext && props.movePrev();
            } else if (touchStartX > touchEndX) {
                props.movePrev && props.moveNext();
            }
            setTouchStartX(null);
        }
    }
    const touchStart = (e) => {
        setTouchStartX(e.changedTouches[0].pageX);
    }
    return (
        <div style={appConsts.isMobile ? styleModalMobile : styleModal} onTouchStart={(e) => touchStart(e)} onTouchEnd={(e) => touchEnd(e)}>
            <div style={styleClose} onClick={() => props.close()}><FaWindowClose size={30} /></div>
            {props.children}
        </div>
    );
}

export default Modal;