import { useState, useContext } from 'react';
import Text from './utils/Text';
import { paintingsMap, genImage } from './utils/paintings.js';
import GalleryFocus from './utils/GalleryFocus';

import AppContext from './utils/appContext'

const months = [
  {name: 'januar', img: paintingsMap.wintermohn},
  {name: 'februar', img: paintingsMap.neubeginn},
  {name: 'märz', img: paintingsMap.fruhlingsn8_},
  {name: 'april', img: paintingsMap.kranzliebe},
  {name: 'mai', img: paintingsMap.clemaitis},
  {name: 'juni', img: paintingsMap.blutenpracht},
  {name: 'juli', img: paintingsMap.mohn},
  {name: 'august', img: paintingsMap.federspiel},
  {name: 'september', img: paintingsMap.kugellauch},
  {name: 'oktober', img: paintingsMap.zauberpoesie},
  {name: 'november', img: paintingsMap.oui},
  {name: 'dezember'}  
];

const style = {
  width: '50%'
}

const styleMonths = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  margin: '-5px',
};

const styleMonthsMobile = {
  ...styleMonths,
  justifyContent: 'space-evenly',
}

const styleMonth = {
  width: '130px',
  height: '130px',
  margin: '5px',
  backgroundColor: '#aba2a2',
  textTransform: 'capitalized',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const styleImage = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
}

const Philosophy = () => {
  const [focus, setFocus] = useState(null);
  const appProps = useContext(AppContext);
  return (
    <div>
      {focus && focus.img && <GalleryFocus preText={focus.name} img={focus.img} close={() => setFocus(null)} />}
      <div style={appProps.isMobile ? {} : style}>
        <Text margin={appProps.isMobile}>
          <p>
            Nach einer längeren Pause hat mich das Malfieber erneut gepackt und ich bin wieder kreativ tätig. 
          </p>
          <p>
            Inspiriert vom Lauf der Natur, entsteht in diesem Jahr (2021) jeden Monat ein neues Kunstwerk. Schaue deshalb immer mal wieder rein und lasse dich überraschen.
          </p>
        </Text>
        <div style={appProps.isMobile ? styleMonthsMobile : styleMonths}>
        { appProps.isMobile && months.map((m, i) => <div onClick={() => setFocus(m)} key={i} style={styleMonth}>{m.img ? genImage(m.img, 450, styleImage) : m.name}</div>) }
        { !appProps.isMobile && months.map((m, i) => <div onMouseEnter={() => setFocus(m)} onMouseLeave={() => setFocus(null)} key={i} style={styleMonth}>{m.img ? genImage(m.img, 450, styleImage) : m.name}</div>) }
        {[...Array(4).keys()].map((i) => <div key={i} style={{width: '140px', height: '0px'}}></div>) /* empty diffs to cheat flexbox algo */ }
        </div>
      </div>
    </div>
  );
}

export default Philosophy;
