import { useContext } from 'react'; 

import AppContext from './utils/appContext'
import Text from './utils/Text';

const Bio = () => {
  const appProps = useContext(AppContext);
  return (
    <Text>
      <p>
        Ich freue mich auf dich!<br/>
        Deine Ideen, Fragen und Wünsche sind jederzeit herzlich willkommen.<br/>
        Über deine Meinung, egal ob Kritik oder Lob, freue ich mich genau so.<br/>
        Gerne nehme ich auch Aufträge entgegen.<br/>
      </p>
      <p>
        Wundervolle Kunstgrüsse<br/>
      </p>
      <p>
        <img alt="" style={{width: appProps.isMobile ? '300px' : '400px'}} src="/signature.webp" />
      </p>
      <p>
        kontakt@kunst-bild.ch
      </p>
    </Text>
  );
}

export default Bio;
