import React from 'react'
import { useState, useContext } from 'react';
import { FaPlayCircle } from 'react-icons/fa';

import { AppProvider } from './appContext'
import AppContext from './appContext'

const styleItemImg = {
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  padding: '5px'
}

const styleItemImgHover = {
  opacity: 0.8
}

const ItemImage = (props) => {
  const [hover, setHover] = useState(false);
  let style = styleItemImg;
  if (hover) {
    style = {...style, ...styleItemImgHover};
  }
  const innerStyle = {width: '100%', height: '100%', objectFit: 'cover'};
  return <div style={style} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>{props.data.genImage(props.data, 450, innerStyle)}</div>;
}

const styleItemText = {
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  padding: '5px',
};

const styleItemTextInner = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Amatic SC',
  color: 'white',
  padding: '20px',
  boxSizing: 'border-box',
};

const ItemText = (props) => {
  const styleTitle = {fontSize: props.small ? '18pt' : '24pt'};
  const styleFooter = {
    fontSize: props.small ? '14pt' : '20pt', 
    flexGrow: 1, 
    display: 'flex',
    alignItems: 'flex-end', 
    textAlign: 'end'
  };
  const styleText = {fontSize: '20pt', flexGrow: 1};
  return <div style={styleItemText}><div style={{...styleItemTextInner, backgroundColor: props.data.color || 'grey'}}>
    <div style={{flexGrow: 1}}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={styleTitle}>{props.data.title}</div>
        { props.data.play && <div style={{cursor: 'pointer'}} onClick={() => props.data.play()}><FaPlayCircle color="white" size="30"/></div>}
      </div>
    </div>
    { props.data.text && <div style={styleText}>{props.data.text}</div>}
    <div style={styleFooter}><div style={{width: '100%'}}>{props.data.footer}</div></div>
  </div></div>;
}

const Item = (props) => {
  if (!props.data) {
    return <div></div>
  } else if (props.data.img) {
    return <ItemImage data={props.data} />
  } else {
    return <ItemText data={props.data} small={props.small} />
  }
}

const styleThree = {
  width: '400px'
}

const styleThreeDouble = {
  height: '200px',
  display: 'flex'
}

const styleThreeSingle = {
  height: '400px',
  width: '100%'
}

function extendRowStyle(context, style) {
  return {
      ...style,
      width: context.isMobile ? '100%': style.width,
      padding: `${context.rowPadding}px 0`
  };
}

export const ThreeDown = (props) => {
  return <div style={extendRowStyle(useContext(AppContext), styleThree)}>
    <div style={styleThreeDouble}><Item data={props.left} small={true}/><Item data={props.right} small={true}/></div>
    <div style={styleThreeSingle}><Item data={props.down}/></div>
  </div>
}
  
export const ThreeUp = (props) => {
  return <div style={extendRowStyle(useContext(AppContext), styleThree)}>
    <div style={styleThreeSingle}><Item data={props.up}/></div>
    <div style={styleThreeDouble}><Item data={props.left} small={true}/><Item data={props.right} small={true}/></div>
  </div>
}

export const Two = (props) => {
  const styleTwo = {...(props.style || {}), width: '300px', height: props.down ? '600px': '400px'};
  const styleSingle = {width: '100%', height: props.down ? '50%' : '100%'};
  return <div style={extendRowStyle(useContext(AppContext), styleTwo)}>
    <div style={styleSingle}><Item data={props.up}/></div>
    { props.down && <div style={styleSingle}><Item data={props.down}/></div> }
  </div>
}

const styleOne = {
    width: '400px',
    height: '400px'
}

export const One = (props) => {
    return <div style={extendRowStyle(useContext(AppContext), styleOne)}><Item data={props.data}/></div>
}

const styleFour = {
    display: 'flex',
    height: '400px',
    width: '400px',
    flexWrap: 'wrap'
}

const styleFourInner = {
    width: '50%',
    height: '50%'
}

export const Four = (props) => {
    return <div style={extendRowStyle(useContext(AppContext), styleFour)}>
        <div style={styleFourInner}><Item data={props.upleft}/></div>
        <div style={styleFourInner}><Item data={props.upright}/></div>
        <div style={styleFourInner}><Item data={props.downleft}/></div>
        <div style={styleFourInner}><Item data={props.downright}/></div>
    </div>
}

export const EmptyBig = () => {
  return <div style={extendRowStyle(useContext(AppContext), {width: '400px'})}/>
}

export const EmptySmall = () => {
  return <div style={extendRowStyle(useContext(AppContext), {width: '300px'})}/>
}
  
const styleGallery = {
    display: 'flex',
    flexWrap: 'wrap'
};

export const Bricks = (props) => {
    const rowPadding = props.rowPadding || 0;
    const style = {...styleGallery, margin: `-${5 + rowPadding}px 0`}
    return <AppProvider value={{...useContext(AppContext), rowPadding}}><div style={style}>{props.children}</div></AppProvider>
}