const photos = [
  ...[1,2,3,4,5].map(i => ({img: `diepoldsau${i}`})),
  ...[1,2,3,4,5].map(i => ({img: `hirschen${i}`})),
  ...[1,2,3,4,5,6,7].map(i => ({img: `kunstmarkt${i}`})),
  ...[1,2,3,4,5].map(i => ({img: `sternenweg${i}`, format: 'jpg'})),
];

const genImage = (image, size, style={}) => {
  const attrs = {
    src: `/projects/${image.img}.${image.format || 'png'}`
  };

  return <img alt="" style={style} {...attrs} />;
}

export const photosMap = photos.reduce((result, current) => ({...result, [current.img]: {...current, genImage}}), {});

export default photos;