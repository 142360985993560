import { useContext } from 'react';
import {genImage} from './paintings.js';
import AppContext from './appContext';
import Modal from './Modal.js';


const styleGalleryFocus = {
  textAlign: 'center',
  width: '550px',
  position: 'fixed',
  left: '50%',
  padding: '0 50px'
}

const styleGalleryFocusMobile = {
  textAlign: 'center',
}

const styleText = {
  marginTop: '25px',
  backgroundColor: 'white'
}

const styleTextMobile = {
  marginTop: '25px',
  color: 'white'
}

const genImageTitle = image => {
  let title = image.title;
  const width = parseInt(image.width);
  const height = parseInt(image.height);
  if (width && height) {
    title += `  - ${width} x ${height}`
  }
  return title;
}

const genImageBlock = (appConsts, props) => (
    <div style={appConsts.isMobile ? styleGalleryFocusMobile : styleGalleryFocus}>
      {genImage(props.img, 450)}
      <div style={appConsts.isMobile ? styleTextMobile : styleText}>
        {props.preText && <div>{props.preText}</div>}
        <div>{genImageTitle(props.img)}</div>
        {props.img.sold && <div>(verkauft)</div>}
      </div>
    </div>
);

const GalleryFocus = (props) => {
  const appConsts = useContext(AppContext);
  return (
    appConsts.isMobile ?
    <Modal close={props.close} movePrev={props.movePrev} moveNext={props.moveNext}> 
      {genImageBlock(appConsts, props)}
    </Modal> :
    <div style={styleGalleryFocus}>
        {genImageBlock(appConsts, props)}
    </div>
  );
}

export default GalleryFocus;
