import { NavLink, useLocation } from 'react-router-dom';
import { useContext, useState } from 'react';
import background from './utils/background.webp';
import { FaInstagram, FaBars } from 'react-icons/fa';
import AppContext from './utils/appContext';

const navStyle = {
    position: 'fixed',
    zIndex: 10,
    width: '100%',
    left: 0,
    top: 0,
    fontFamily: 'Amatic SC',
    fontSize: '30px',
}

const navLineStyleCommon = {
    height: '50px',
    display: 'flex',
}

const navStyleMobile = {
    backgroundColor: '#aba2a2',
}

const navLineStyleMobile = {
    ...navLineStyleCommon,
    alignItems: 'center', 
    justifyContent: 'space-between'
}

const navLineStyle = {
    ...navLineStyleCommon,
    backgroundColor: '#aba2a2',
    justifyContent: 'center',
}

const navLineInnerStyle = {
    width: '1100px',
    display: 'flex',
    alignItems: 'center',   
}

const navItemsStyle = {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
}

const navItemStyle = {
    textDecoration: 'none',
    color: 'black'
}

const navItemActiveStyle = {
    fontWeight: 'bold'
}

const navAboveStyle = {
    height: '70px',
    backgroundImage: `url('${background}')`,
    backgroundColor: 'white'
}

const navAboveStyleMobile = {
    ...navAboveStyle,
    height: '25px'
}

const navItems = [
    {name: 'home', link: '/home'},
    {name: 'aktuell', link: '/current'},
    {name: 'über mich', link: '/bio'}, 
    {name: 'philosophie', link: '/philosophy'}, 
    {name: 'Galerie', link: '/gallery'}, 
    {name: 'ausstellungen & projekte', link: '/projects'}, 
    {name: 'kontakt', link: '/contact'}
];

const instagram = 'https://www.instagram.com/sternzauberfunkel/';

const LineMobile = () => {
    const [open, setOpen] = useState(false);
    const currentPath = useLocation().pathname;
    const currentNavItem = navItems.filter(navItem => currentPath.startsWith(navItem.link))[0];
    return (
        <div style={navStyleMobile}>
            <div style={navLineStyleMobile}>
                <img alt="" style={{width: '75px'}} src="/logo.webp"/>
                {currentNavItem && !open && <div style={{fontWeight: 'bold'}}>{currentNavItem.name}</div>}
                <div style={{display: 'flex', alignItems: 'center', padding: '0 10px 0 35px'}} onClick={() => setOpen(!open)}><FaBars size={30}/></div>
            </div>
            {open && <div>{
                navItems.map((navItem, i) => <div style={{textAlign: 'center', padding: '10px'}} key={i} ><NavLink onClick={() => setOpen(false)} to={navItem.link} style={navItemStyle} activeStyle={navItemActiveStyle}>{navItem.name}</NavLink></div>    )
                }
                <a style={{...navItemStyle, textAlign: 'center', display: 'block'}} href={instagram} target="_blank" rel="noreferrer" onClick={() => setOpen(false)}><FaInstagram/></a>
            </div>}
        </div>
    );
}

const LineDesktop = () => {
    return (
        <div style={navLineStyle}>
            <div style={navLineInnerStyle}>
                <img alt="" style={{width: '125px'}} src="/logo.webp"/>
                <div style={navItemsStyle}>{
                    navItems.map((navItem, i) => <NavLink key={i} to={navItem.link} style={navItemStyle} activeStyle={navItemActiveStyle}>{navItem.name}</NavLink>)
                }
                <a style={{...navItemStyle, display: 'flex', alignItems: 'center'}} href={instagram} target="_blank" rel="noreferrer"><FaInstagram size={30}/></a>
                </div>
            </div>
        </div>
    );
}

function Navigation(props) {
    const appConsts = useContext(AppContext)
    return (
        <div style={navStyle}>
            <div style={appConsts.isMobile ? navAboveStyleMobile : navAboveStyle}></div>
            { appConsts.isMobile ? <LineMobile /> : <LineDesktop />}
        </div>
    );
  }
  
  export default Navigation;