import { useContext } from 'react';
import AppContext from './utils/appContext';

const style = {
    margin: '80px 0 20px 80px',
    backgroundColor: 'white',
    color: 'rgb(171, 162, 162)'
}

const styleMobile = {
    ...style,
    margin: '40px 0 10px 10px'
}

const Footer = () => {
    const appConsts = useContext(AppContext);
    return (
        <div style={appConsts.isMobile ? styleMobile : style}>&copy; by Stephanie Graf</div>
    );
};
  
export default Footer;