import { paintingsMap } from './utils/paintings.js';
import { Bricks, Two, ThreeDown, ThreeUp } from './utils/Bricks';

const texts = [{
  title: 'Die Kunst muss nichts. Die Kunst darf alles.',
  footer: 'Ernst Fischer',
  color: '#adaca4'
}, {
  title: 'Das Geheimnis der Kunst liegt darin, dass man nicht sucht, sondern findet.',
  footer: 'Pablo Picasso',
  color: '#a79a9a'
}, {
  title: 'Kunst ist die Schnittstelle, an der sich Vergangenheit und Zukunft begegnen und neue Wege entdecken.',
  footer: 'Marion T. Douret',
  color: '#645d6d'
}, {
  title: 'Kreativität ist, wenn einem bei dem, was einem auffällt, etwas einfällt.',
  footer: 'Gerhard Uhlenbruck',
  color: '#a9a0a0'
}, {
  title: 'Kreativität bricht das Leben auf, lässt neue Kräfte wachsen und führt dich zu dir selbst.',
  footer: 'Marion T. Douret',
  color: '#544c4c'
}];

const Home = () => {
  return (
    <Bricks>
      <ThreeDown left={paintingsMap.wintermohn} right={paintingsMap.kuoxro2_} down={paintingsMap.winterstarre} />
      <Two up={texts[4]} down={paintingsMap['ver-traut']} />
      <ThreeUp left={texts[0]} right={paintingsMap.Canrose} up={paintingsMap.feuerrost} />
      <Two up={texts[1]} down={paintingsMap.winterzeit2_} />
      <ThreeUp left={paintingsMap.aqua1_} right={paintingsMap.eiszeit1_} up={paintingsMap.zeitlos} />
      <ThreeDown left={paintingsMap.winterzauber} right={paintingsMap.poesie} down={paintingsMap.hazy_rust1_} />
      <ThreeDown left={paintingsMap.eiszeit2_} right={paintingsMap.winterzeit1_} down={paintingsMap.hazy_rust2_} />
      <Two down={paintingsMap.mittsommer1_} up={texts[2]} />
      <ThreeUp right={texts[3]} left={paintingsMap.oriental} up={paintingsMap.zauber} />
    </Bricks>
  );
}

export default Home;