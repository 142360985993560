import { useState, useContext } from 'react';

import AppContext from './utils/appContext';

import { Bricks, Two, Four, One, EmptyBig, EmptySmall } from './utils/Bricks';
import Book from './utils/Book';
import { photosMap } from './utils/photos';

const bilderbuchMimi = [...Array(28).keys()].map(i => `/bilderbuch/mimi/${i + 1}.webp`);
const bilderbuchMalin = [...Array(29).keys()].map(i => `/bilderbuch/malin/${i}.webp`);

const Projects = () => {
  const [bookImages, setBookImages] = useState(null);
  const appConsts = useContext(AppContext);

  const texts = [{
    title: 'Sternenwegli Oberfrick',
    footer: 'Weihnachten 2020',
    text: 'Eine Weihnachtsinstallation zur selbstgeschriebenen Geschichte "Das Weihnachtswunder"'
  }, {
    title: 'Bilderbuch',
    footer: '2019',
    text: 'Zur Geburt unserer wundervollen Tochter',
    play: () => setBookImages(bilderbuchMalin)
  }, {
    title: 'Ausstellung Gesundheitszentrum Diepoldsau',
    footer: '2015 / 2016'
  }, {
    title: 'Bilderbuch',
    footer: '2015',
    text: 'Zur Geburt unseres wundervollen Sohnes',
    play: () => setBookImages(bilderbuchMimi)
  }, {
    title: 'Kunstmarkt im Sprützähüsli',
    footer: 'November 2014'
  }, {
    title: 'Vernissage Café Hirschen Magden',
    footer: 'Frühling 2012'
  }].map(t => ({...t, color: '#adaca4'}));

  const styleSpaceBefore = appConsts.isMobile ? { marginTop: '50px' } : {};

  return (
    <div>
      { bookImages && <Book images={bookImages} close={() => setBookImages(null)} /> }
      <Bricks rowPadding={appConsts.isMobile ? 0 : 5}>
        <Two up={texts[0]} />
        <One data={photosMap.sternenweg1} />
        <Four upleft={photosMap.sternenweg2} upright={photosMap.sternenweg3} downleft={photosMap.sternenweg4} downright={photosMap.sternenweg5} />

        <Two up={texts[1]} style={styleSpaceBefore} /><EmptyBig/><EmptySmall/>

        <Two up={texts[2]} style={styleSpaceBefore} />
        <One data={photosMap.diepoldsau1} />
        <Four upleft={photosMap.diepoldsau2} upright={photosMap.diepoldsau3} downleft={photosMap.diepoldsau4} downright={photosMap.diepoldsau5} />

        <Two up={texts[3]} style={styleSpaceBefore} /><EmptyBig/><EmptySmall/>
        
        <Two up={texts[4]} style={styleSpaceBefore} />
        <One data={photosMap.kunstmarkt1} />
        <Four upleft={photosMap.kunstmarkt2} upright={photosMap.kunstmarkt3} downleft={photosMap.kunstmarkt4} downright={photosMap.kunstmarkt5} />

        <Two up={texts[5]} style={styleSpaceBefore} />
        <Four upleft={photosMap.hirschen2} upright={photosMap.hirschen3} downleft={photosMap.hirschen4} downright={photosMap.hirschen5} />
        <One data={photosMap.hirschen1} />

      </Bricks>
    </div>
  );
};

export default Projects;
