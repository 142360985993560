import { useState, useContext } from 'react';
import paintings, {genImage} from './utils/paintings';

import AppContext from './utils/appContext'

import GalleryFocus from './utils/GalleryFocus';

const styleGallery = {
  display: 'flex'
};

const styleGalleryLeft = {
  width: '50%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  margin: '-3px',
};

const styleGalleryImgSmall = {
  fontSize: 0,
  textAlign: 'center',
  width: '78px',
  height: '78px',
  boxSizing: 'border-box',
  padding: '3px'
}

const styleGalleryMobile = {
  margin: '-3px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
};

const styleImage = {
  width: '100%',
  height: '100%'
}

const genEmptyFillers = () => [...Array(12).keys()].map((i) => <div key={i} style={{width: '78px', height: '0px'}}></div>) /* empty diffs to cheat flexbox algo */

const KBGallery = () => {
  const [focus, setFocus] = useState(null);
  const appConsts = useContext(AppContext);

  const setFocusIndex = (i) => {
    let newIndex = i;
    if (i < 0) {
      newIndex = paintings.length - 1;
    } else if (i >= paintings.length) {
      newIndex = 0;
    }

    setFocus(paintings[newIndex]);
  }

  return (
    appConsts.isMobile ?
    <div style={styleGalleryMobile}>{
      paintings.map((p, i) => 
        <div key={i} style={styleGalleryImgSmall} onClick={() => setFocus(p)}>
          {genImage(p, 75, styleImage)}
        </div>)}
        { genEmptyFillers() }
        {focus && <GalleryFocus moveNext={() => setFocusIndex(focus.i + 1)} movePrev={() => setFocusIndex(focus.i - 1)} close={() => setFocus(null)} img={focus}/>}
    </div> :
    <div style={styleGallery}>
      <div style={styleGalleryLeft}>{
        paintings.map((p, i) => 
          <div key={i} style={styleGalleryImgSmall} onMouseEnter={() => setFocus(p)} onMouseLeave={() => setFocus(null)}>
            {genImage(p, 75, styleImage)}
          </div>)
        }
        { genEmptyFillers() }
      </div>
      {focus && <GalleryFocus img={focus}/>}
    </div> 
  );
}

export default KBGallery;
