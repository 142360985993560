import Text from './utils/Text';

const styleTitle={
  fontFamily: 'Fredericka the Great', 
  fontSize: '20pt',
  fontWeight: 'normal'
};

const Bio = () => (
  <Text>
    <h2 style={styleTitle}>Mein Weg</h2>
    In Magden aufgewachsen, jetzt wohnhaft im oberen Fricktal<br/>
    Besuch der Primarschule in Magden, Bezirksschule in Rheinfelden und des Gymnasiums in Muttenz<br/>
    Ausbildung zur Primarlehrerin an der Pädagogischen Hochschule der FHNW in Liestal<br/>
    Seither tätig als Primarlehrerin und Praxislehrerin<br/>
    Autorin bei einem Lehrmittelprojekt<br/>
    Weiterbildung «Achtsamkeit in der Schule»<br/>
    Neben dem Sport und der Musik grosses Interesse für Kunst und Kultur<br/>
    Diverse Kurse und Weiterbildungen rund ums Gestalten<br/>
    Mama zweier wundervoller Kinder<br/>
    Mit Spaziergängen in der Natur und Yoga tanke ich Energie<br/>
    Mit viel Leidenschaft dekoriere ich unser schönes Zuhause, nähe, probiere mich beim Backen oder bastle mit unseren Kindern<br/>

    <h2 style={styleTitle}>Was ich gerne mag</h2>
    Wunderkerzen &bull; Eukalyptus &bull; Federn &bull; Design &bull; Bilderbücher &bull; Zaubermomente in der Natur &bull; feines Essen &bull; tanzen &bull; Klassische Musik am Sonntagmorgen – Cappuccino &bull; lachende Kindergesichter &bull; Bücher &bull; Yoga &bull; Überraschungen &bull; Schokolade &bull; Norden &bull; Sonnenschein &bull; Erdfarben &bull; goldener Schnitt &bull; singen &bull; querdenken<br/>

  </Text>
);

export default Bio;
