const paintings = [
  {
    img: 'federspiel',
    title: 'federspiel',
    width: 50,
    height: 60,
    format: 'webp'
  },
  {
    img: 'zauberpoesie',
    title: 'zauberpoesie',
    width: 22,
    height: 32,
    format: 'webp'
  },
  {
    img: 'oui',
    title: 'oui',
    width: 28,
    height: 28,
    format: 'webp'
  },
  {
    img: 'mohn',
    title: 'mohnkapsel im abendrot',
    width: 20,
    height: 50,
    format: 'webp'
  },
  {
    img: 'blutenpracht',
    title: 'blütenpracht',
    width: 50,
    height: 50,
    format: 'webp'
  },
  {
    img: 'clemaitis',
    title: 'cleMAItis',
    width: 30,
    height: 30,
    format: 'webp'
  },
  {
    img: 'fruhlingsn8_',
    title: 'frühlingsn8',
    width: 30,
    height: 30,
    format: 'webp'
  },
  {
    img: 'neubeginn',
    title: 'neubeginn',
    width: 40,
    height: 40,
    format: 'webp'
  },
  {
    img: 'kranzliebe',
    title: 'kranzliebe',
    width: 40,
    height: 40,
    format: 'webp'
  },
  {
    img: 'wintermohn',
    title: 'wintermohn',
    width: 40,
    height: 40,
    format: 'jpeg'
  },
  {
    img: 'hazy_rust1_',
    title: 'hazy rust 1',
    width: 30,
    height: 30,
    format: 'jpeg'
  },
  {
    img: 'hazy_rust2_',
    title: 'hazy rust 2',
    width: 30,
    height: 30,
    format: 'jpeg'
  },
  {
    img: 'kugellauch',
    title: 'kugellauch',
    width: 50,
    height: 50,
    format: 'jpeg'
  },
  {
    img: 'lichtzauber',
    title: 'lichtzauber',
    width: 40,
    height: 40,
    format: 'jpeg'
  },
  {
    img: 'landmark',
    title: 'landmark',
    sold: true
  },
  {
    img: 'zauber',
    title: 'zauber',
    sold: true
  },
  {
    img: 'licht',
    title: 'licht',
    sold: true
  },
  {
    img: 'poesie',
    title: 'poesie',
    sold: true
  },
  {
    img: 'eiszeit1_',
    title: 'eiszeit 1',
    sold: true
  },
  {
    img: 'eiszeit2_',
    title: 'eiszeit 2',
    sold: true
  },
  {
    img: 'zeitfenster3_',
    title: 'zeitfenster 3',
    sold: true
  },
  {
    img: 'familie',
    title: 'familie',
    sold: true
  },
  {
    img: 'kuoxro1_',
    title: 'kuoxro 1',
    sold: true
  },
  {
    img: 'kuoxro2_',
    title: 'kuoxro 2',
    sold: true
  },
  {
    img: 'quarto',
    title: 'quarto',
    width: 20,
    height: 20
  },
  {
    img: 'regenrinne',
    title: 'regenrinne',
    width: 50,
    height: 40
  },
  {
    img: 'unterwasser',
    title: 'unterwasser',
    sold: true
  },
  {
    img: 'zusammenkunft',
    title: 'zusammenkunft',
    sold: true
  },
  {
    img: 'feuerrost',
    title: 'feuerrost',
    width: 70,
    height: 70
  },
  {
    img: 'aqua1_',
    title: 'aqua 1',
    width: 40,
    height: 40
  },
  {
    img: 'aqua2_',
    title: 'aqua 2',
    width: 40,
    height: 40
  },
  {
    img: 'winterzauber',
    title: 'winterzauber',
    width: 70,
    height: 70
  },
  {
    img: 'mittsommer1_',
    title: 'mittsommer 1',
    width: 50,
    height: 50
  },
  {
    img: 'mittsommer2_',
    title: 'mittsommer 2',
    width: 50,
    height: 50
  },
  {
    img: 'apricot',
    title: 'apricot',
    sold: true
  },
  {
    img: 'lindengruen',
    title: 'lindengrün',
    width: 30,
    height: 30
  },
  {
    img: 'petrol',
    title: 'petrol',
    sold: true
  },
  {
    img: 'besetzt',
    title: 'besetzt',
    width: 40,
    height: 40
  },
  {
    img: 'beschirmt',
    title: 'beschirmt',
    width: 40,
    height: 40
  },
  {
    img: 'bewegt',
    title: 'bewegt',
    width: 40,
    height: 40
  },
  {
    img: 'quartett',
    title: 'quartett',
    width: 20,
    height: 20
  },
  {
    img: 'winterstarre',
    title: 'winterstarre',
    sold: true
  },
  {
    img: 'ver-traut',
    title: 'ver-traut',
    width: 40,
    height: 30
  },
  {
    img: 'abendrot1_',
    title: 'abendrot 1',
    width: '0',
    height: '0',
    sold: true
  },
  {
    img: 'abendrot2_',
    title: 'abendrot 2',
    width: '0',
    height: '0'
  },
  {
    img: 'auflauf1_',
    title: 'auflauf 1',
    width: '50',
    height: '50'
  },
  {
    img: 'auflauf2_',
    title: 'auflauf 2',
    width: '50',
    height: '50'
  },
  {
    img: 'augenblick',
    title: 'augenblick',
    width: '0',
    height: '0'
  },
  {
    img: 'durchblick',
    title: 'durchblick',
    width: '60',
    height: '60'
  },
  {
    img: 'Canrose',
    title: 'canrose',
    width: '100',
    height: '50'
  },
  {
    img: 'endzeit',
    title: 'endzeit',
    width: '40',
    height: '30',
    sold: true
  },
  {
    img: 'feuerball',
    title: 'feuerball',
    width: '30',
    height: '60',
    sold: true
  },
  {
    img: 'folla',
    title: 'folla',
    width: '0',
    height: '0'
  },
  {
    img: 'hope',
    title: 'hope',
    width: '45',
    height: '45',
    sold: true
  },
  {
    img: 'oeffnung',
    title: 'öffnung',
    width: '30',
    height: '30',
    sold: true
  },
  {
    img: 'oriental',
    title: 'oriental',
    width: '0',
    height: '0',
    sold: true
  },
  {
    img: 'sauvage',
    title: 'sauvage',
    width: '40',
    height: '40'
  },
  {
    img: 'think',
    title: 'think',
    width: '0',
    height: '0',
    sold: true
  },
  {
    img: 'transversal',
    title: 'transversal',
    width: '20',
    height: '50'
  },
  {
    img: 'weitblick',
    title: 'weitblick',
    width: '80',
    height: '30',
    sold: true
  },
  {
    img: 'winterzeit1_',
    title: 'winterzeit 1',
    width: 50,
    height: 50
  },
  {
    img: 'winterzeit2_',
    title: 'winterzeit 2',
    width: '0',
    height: '0',
    sold: true
  },
  {
    img: 'zeitfenster',
    title: 'zeitfenster 1',
    width: '30',
    height: '30',
    sold: true
  },
  {
    img: 'zeitfenster2_',
    title: 'zeitfenster 2',
    width: '0',
    height: '0',
    sold: true
  },
  {
    img: 'zeitlos',
    title: 'zeitlos',
    width: '40',
    height: '40',
    sold: true
  }
].map((p, i) => ({...p, i}));

export const genImage = (image, size, style={}) => {
  const attrs = (image.format === 'jpeg' ? {
    srcSet: `/gallery/${image.img}${size}_2x.${image.format} 2x`,
    src: `/gallery/${image.img}${size}.${image.format}`
  } : {
    src: `/gallery/${image.img}${size}.${image.format || 'png'}`
  })

  return <img alt="" style={{...style, maxWidth: '100%'}} {...attrs} />;
}

export const paintingsMap = paintings.reduce((result, current) => ({...result, [current.img]: {...current, genImage}}), {});


export default paintings;