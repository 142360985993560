import {  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { useMediaQuery } from 'react-responsive';

import './App.css';

import { AppProvider } from './utils/appContext'

import Navigation from './Navigation';
import KBGallery from './KBGallery';
import Projects from './Projects';
import Bio from './Bio';
import Philosophy from './Philosophy';
import Home from './Home';
import Current from './Current';
import Contact from './Contact';
import Footer from './Footer';

const appStyle = {
  margin: 'auto',
  width: '1100px',
  overflow: 'hidden',
};

const appStyleMobile = {
  width: '100%'
}

const contentStyle = {
  marginTop: '200px',
};

const contentStyleMobile = {
  marginTop: '100px'
}

function App() {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 1100px)'
  })
  return (
    <AppProvider value={{isMobile}}>
      <div style={isMobile ? appStyleMobile : appStyle}>
        <Router>
          <Navigation/>
          <div style={isMobile ? contentStyleMobile : contentStyle}>
            {isMobile}
            <Switch>
                <Route path="/gallery">
                  <KBGallery />
                </Route>
                <Route path="/projects">
                  <Projects />
                </Route>
                <Route path="/bio">
                  <Bio />
                </Route>
                <Route path="/philosophy">
                  <Philosophy />
                </Route>
                <Route path="/current">
                  <Current />
                </Route>
                <Route path="/contact">
                  <Contact/>
                </Route>
                <Route path="/home">
                  <Home />
                </Route>
                <Redirect to='/home' />
              </Switch>
            </div>
            <Footer/>
        </Router>
      </div>
    </AppProvider>
  );
}

export default App;
