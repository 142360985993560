import { useContext } from 'react';

import AppContext from './appContext'

const style = {
    backgroundColor: 'white',
    lineHeight: '25pt',
    fontSize: '15pt'
};

const defaultProps = {
    margin: true
}

function Text(props) {
    const appProps = useContext(AppContext);
    const styleMargin = {
        margin: appProps.isMobile ? '0 10px' : '0 80px',
    }
    const realProps = {...defaultProps, ...props};
    const realStyle = realProps.margin ? {...style, ...styleMargin} : style;
    return (
        <div style={realStyle}>{
            props.children    
        }</div>
    );
  }
  
  export default Text;