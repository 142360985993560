import { useEffect, useState, useRef } from 'react';
import Modal from './Modal.js';
import {PageFlip} from 'page-flip';

const Book = (props) => {
    const [width, setWidth] = useState(window.innerWidth);

    const handleResizeChange = () => {
        // sometimes, the width is not ready yet -> get it a little bit later
        setTimeout(function(){
            setWidth(window.innerWidth);
        });
    }

    useEffect(() => {
        window.addEventListener("resize", handleResizeChange);
        return () => window.removeEventListener("resize", handleResizeChange);
    }, []);

    const book = useRef(null);
    useEffect(() => {
        const bookWidth = width / 2 < 500 ? width / 2 : 500;
        const bookHeight = bookWidth / 500 * 350;
        const bookChild = document.createElement('div');

        bookChild.classList.add('page-book');
        book.current.appendChild(bookChild);
        const pageFlip = new PageFlip(
            bookChild, {
                width: bookWidth,
                height: bookHeight,
                showCover: true,
                usePortrait: false
            }
        );
        pageFlip.loadFromImages(props.images);
        return function cleanup() {
            pageFlip.destroy();
        };
    }, [width, props.images]);

    return (
        <Modal close={props.close}><div ref={book}></div></Modal>
    );
}

export default Book;